import RateReviewIcon from '@mui/icons-material/RateReview';
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, CircularProgress, IconButton, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AssistantInterface } from './Assistant';
import ChatBubble from './ChatBubble';
import { SERVER_URL } from './environment';

interface ChatModalContentProps {
    assistant: AssistantInterface;
    thread_id?: string;
}

const ChatModalContent = ({ assistant, thread_id }: ChatModalContentProps) => {
    const [message, setMessage] = useState('');
    const [userId, setUserId] = useState('');
    const [channelId, setChannelId] = useState('');
    const [messages, setMessages] = useState<Array<{id: number, text: string, sender: 'bot' | 'user'}>>(
        !assistant.name && !assistant.description && assistant.starters.length === 0 ? 
        [{id: 0, text: "How can I help you today?", sender: 'bot'}] : 
        []);
    const [isLoading, setLoading] = useState(false);
    const messagesEndRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (thread_id) {
            const fetchMessages = async () => {
                setLoading(true);
                await axios.get(`${SERVER_URL}/assistants/${assistant.id}/threads/${thread_id}`).then(response => {
                    setMessages(response.data.messages);
                }).catch(error => {
                    console.error(error);
                }).finally(() => setLoading(false));
            };
            fetchMessages();
        }
    }, [assistant.id, thread_id]);

    useEffect(() => {
        let storedUserId = sessionStorage.getItem('userId');
        let storedChannelId = sessionStorage.getItem('channelId');
        if (!storedUserId) {
            storedUserId = uuidv4();
            if (storedUserId !== null)
                sessionStorage.setItem('userId', storedUserId);
        }
        if (!storedChannelId) {
            storedChannelId = uuidv4();
            if (storedChannelId !== null)
                sessionStorage.setItem('channelId', storedChannelId);
        }
        if (storedUserId && storedChannelId) {
            setUserId(storedUserId);
            setChannelId(storedChannelId);
        }
    }, []);

    useEffect(() => {
        if (messagesEndRef !== null && messagesEndRef.current !== null && messagesEndRef) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages]);

    const sendMessageToBackend = async (userMessage: string) => {
        setLoading(true); // Start loading
        const response = await axios.post(`${SERVER_URL}/assistants/${assistant.id}/chat`, {
            user_id: userId,
            channel_id: channelId,
            message: userMessage,
        }).finally(() => setLoading(false));
        return response.data.response;
    };

    const handleSendMessage = async (message: string) => {
        if (message.trim()) {
            // Clear input field
            setMessage('');
            // Update conversation with user message
            setMessages(messages => [...messages, { id: messages.length, text: message, sender: 'user' }]);
            // Send message to backend and get response
            const botResponse = await sendMessageToBackend(message);
            if (botResponse) {
                // Update conversation with bot response
                setMessages(messages => [...messages, { id: messages.length + 1, text: botResponse, sender: 'bot' }]);
            }
        }
    };

    const theme = useTheme();

    return (
        <Box className={`flex justify-center items-center h-full ${assistant.theme === 'dark' ? 'bg-black text-white' : 'bg-white text-black'}`}>
            <Box
                maxHeight={assistant.isFullPage ? "100vh" : "80vh"}
                className={`${assistant.isFullPage ? 'h-screen' : 'h-full '} py-16`}
            >
                <Box
                    sx={{
                        width: `90vw`,
                        [theme.breakpoints.up('md')]: {
                            width: `${assistant.scale * 40}rem`,
                        },
                        [theme.breakpoints.up('lg')]: {
                            width: `${assistant.scale * 50}rem`,
                        },
                        [theme.breakpoints.up('xl')]: {
                            width: `${assistant.scale * 60}rem`,
                        },
                    }}
                    className={`h-full flex flex-col`}>
                    <Box className="flex justify-end">
                        <Tooltip title="New Chat">
                            <IconButton onClick={() => setMessages([])} className="mb-2">
                                <RateReviewIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    {(messages?.length === 0 || (!assistant.starters && messages?.length === 1)) &&
                        <Box

                            display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={2} className="items-center h-full">
                            <Box display="flex" flexDirection="row" justifyContent="center" className="items-center">
                                {assistant.icon && <img src={assistant.icon} alt="Chatbot icon" className="rounded-full" style={{ width: '80px', height: '80px' }} />}
                            </Box>
                            <Box
                                display="flex" flexDirection="column" justifyContent="center" alignItems="center" className="items-center mt-2">
                                <Typography variant="h4" align="center">{assistant.name}</Typography>
                                <Typography variant="body1" align="center">{assistant.description}</Typography>
                            </Box>
                        </Box>
                    }
                    {messages?.length > 0 && <Box
                        flexGrow={1} overflow="auto" mb={2} maxHeight={assistant.isFullPage ? "100%" : "70vh"}>
                        {messages.map(msg => (
                            <ChatBubble key={msg.id} text={msg.text} sender={msg.sender} senderName={msg.sender === 'bot' ? assistant.name : "You"} icon={assistant.icon} isDarkMode={assistant.theme === 'dark'} />
                        ))}
                        <div ref={messagesEndRef} /> {/* Empty div for auto-scrolling */}
                    </Box>}
                    {messages?.length === 0 && <Box
                        className="mb-4">
                        {assistant.starters && assistant.starters.length > 0 &&
                            <Box display="flex" flexWrap="wrap" justifyContent="space-between" gap={2} mt={1}>
                                {assistant.starters.map((starter, index) => {
                                    if (starter.trim() === '') return null;
                                    return (
                                        <Button
                                            key={index}
                                            variant="outlined"
                                            color="primary"
                                            onClick={async () => {
                                                await handleSendMessage(starter.trim());
                                            }}
                                            sx={{
                                                flexBasis: '100%', // Default flexBasis
                                                [theme.breakpoints.up('lg')]: {
                                                    flexBasis: 'calc(50% - 0.5rem)', // flexBasis for md and up
                                                },
                                            }}
                                            style={{
                                                justifyContent: 'start'
                                            }}
                                        >
                                            <Typography noWrap>
                                                {starter.trim()}
                                            </Typography>
                                        </Button>
                                    );
                                })}
                            </Box>
                        }
                    </Box>}
                    <Box
                        display="flex" flexDirection="row" justifyContent="space-between" mb={2}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Type your message..."
                            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage(message)}
                            disabled={isLoading}
                        />
                        <Box className="ml-2">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleSendMessage(message)}
                                disabled={isLoading}
                                className="h-full"
                            >
                                {isLoading ? <CircularProgress size={24} /> : <SendIcon />}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ChatModalContent;
