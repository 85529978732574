import { StyledEngineProvider, createTheme } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Assistant from './Assistant';
import AssistantRoute from './AssistantRoute';
import './index.css';

export const generateTheme = (rootElement: HTMLElement, theme: 'dark' | 'light', scale = 1) => {
  return createTheme({
    components: {
      MuiPopover: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiPopper: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiDialog: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiModal: {
        defaultProps: {
          container: rootElement,
        },
      },
    },
    palette: {
      mode: theme,
      primary: {
        main: '#3062A6',
      },
      secondary: {
        main: '#d6336c',
      },
      warning: {
        main: '#f44336',
      },
    },
    typography: {
      fontFamily: "'Roboto', sans-serif",
      htmlFontSize: 16 / scale,
      button: {
        textTransform: 'inherit'
      }
    },
  });
}

const rootElement = document.getElementById('root');
if (rootElement !== null) {
  const root = ReactDOM.createRoot(rootElement);
  if (root) {
    import('./root.css').then(() => {
      root.render(
        <React.StrictMode>
          <StyledEngineProvider injectFirst>
            <Router>
              <Routes>
                <Route path="/" element={<Navigate to="/assistant/asst_wyUiru8TWvQCBqEu3UdrICvR" />} />
                <Route path="/assistant/:assistant_id" element={<AssistantRoute />} />
                <Route path="/assistants/:assistant_id" element={<AssistantRoute />} />
              </Routes>
            </Router>
          </StyledEngineProvider>
        </React.StrictMode>
      );
    });
  }
}

class GPTChatbotWidget extends HTMLElement {
  root: ReactDOM.Root | null;
  constructor() {
    super();
    this.root = null; // Initialize the root property
  }

  static get observedAttributes() {
    return ['assistant_id', 'thread_id'];
  }

  connectedCallback() {
    this.root = ReactDOM.createRoot(this); // Create and store the root instance
    this.render();
  }

  render() {
    if (this.assistant_id !== null && this.root !== null)
      this.root.render(
        <StyledEngineProvider injectFirst>
            <Assistant assistant_id={this.assistant_id} root={this} thread_id={this.thread_id}/>
        </StyledEngineProvider>
      );
  }

  disconnectedCallback() {
    if (this.root) {
      this.root.unmount(); // Use root.unmount() to unmount the component
    }
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    // React to attribute changes if needed
    if (oldValue !== newValue) {
      this.render();
    }
  }

  get assistant_id() {
    return this.getAttribute('assistant_id');
  }

  get thread_id(): string | undefined{
    return this.getAttribute('thread_id') || undefined;
  }

}

customElements.define('reliable-genius-assistant', GPTChatbotWidget);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
