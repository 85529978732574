import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { Box, Typography } from '@mui/material';
import { MuiMarkdown } from 'mui-markdown';
import React from 'react';
import ChatBotIcon from './ChatBotIcon';


export interface ChatBubbleProps {
    text: string,
    sender: string,
    senderName: string,
    icon: string | undefined,
    isDarkMode: boolean
}

const ChatBubble = ({ text, sender, senderName, icon, isDarkMode }: ChatBubbleProps) => {
    const isBotMessage = sender === 'bot';

    return (
        <Box
            className={`flex ${isBotMessage ? 'justify-start' : 'justify-end'} mb-2`}
        >
            {isBotMessage && <Box className="mr-2"><ChatBotIcon icon={icon} /></Box>}
            <Box className="flex flex-col">
                <Box className={`mb-2 ${!isBotMessage && 'text-right'}`}>
                    <Typography>{senderName}</Typography>
                </Box>

                <Box
                    className={`p-2 rounded-lg ${isBotMessage ? (isDarkMode ? 'bg-gray-900' : 'bg-gray-50') : (isDarkMode ? 'bg-blue-900' : 'bg-blue-300')} ${isDarkMode ? 'text-white' : 'text-black'} max-w-9/10`}
                >
                    {isBotMessage ?
                        <MuiMarkdown>{text}</MuiMarkdown> :
                        <Typography>{text}</Typography>
                    }
                </Box>
            </Box>
            {!isBotMessage && <Box className="ml-2"><ChatBubbleIcon style={{ fontSize: 30 }}/></Box>}
        </Box>
    );
};

export default ChatBubble;