import ChatIcon from '@mui/icons-material/Chat';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import './ChatIconButton.css';

const ChatIconButton = ({ onClick }: { onClick: () => void }) => {
    return (
        <div className="fixed bottom-8 right-8">
            <IconButton onClick={onClick} color="primary" aria-label="open chat" style={{ fontSize: 80 }}>
                <ChatIcon style={{ fontSize: 80 }} className="bounce" />
            </IconButton>
        </div>
    );
};

export default ChatIconButton;