import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Assistant from './Assistant';

const AssistantRoute = () => {
    const { assistant_id } = useParams();
    const query = new URLSearchParams(useLocation().search);
    const thread_id = query.get('thread_id') || undefined;

    const root = document.getElementById('root');
    if (root === null) throw new Error('Root element not found');

    return (
        <>
            {assistant_id &&
                <Assistant assistant_id={assistant_id} root={root} thread_id={thread_id}/>}
        </>
    );
}

export default AssistantRoute;