import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Modal, Paper } from '@mui/material';
import React from 'react';
import { AssistantInterface } from './Assistant';
import ChatModalContent from './ChatModalContent';

const ChatModal = ({ assistant, open, onClose }: { assistant: AssistantInterface, open: boolean, onClose: () => void }) => {
    return (
        <Modal open={open} onClose={onClose} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Paper style={{ overflow: 'auto' }}>
                <Box className={`rounded-lg shadow-lg p-6 mx-auto space-y-4 relative  ${assistant.theme === 'dark' ? 'bg-black text-white' : 'bg-white text-black'}`}>
                    <Button onClick={onClose} className="absolute top-0 right-0 p-2">
                        <CloseIcon />
                    </Button>
                    <ChatModalContent assistant={assistant}></ChatModalContent>
                </Box>
            </Paper>
        </Modal>
    );
};

export default ChatModal;