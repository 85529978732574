import { Box, CircularProgress, ScopedCssBaseline, THEME_ID, ThemeProvider, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { generateTheme } from '.';
import ChatIconButton from './ChatIconButton';
import ChatModal from './ChatModal';
import ChatModalContent from './ChatModalContent';
import { SERVER_URL, SUPABASE_ANON_KEY } from './environment';

// axios.defaults.withCredentials = true;
axios.defaults.headers.common['Authorization'] = `Bearer ${SUPABASE_ANON_KEY}`;

export interface AssistantInterface {
    id: string;
    name: string;
    description: string;
    model: string;
    hasFunctions: boolean;
    hasCodeInterpreter: boolean;
    hasRetrieval: boolean;
    hasFiles: boolean;
    channels: string[];
    starters: string[];
    icon?: string;
    instructions?: string;
    isFullPage: boolean;
    isOpen: boolean;
    theme: 'dark' | 'light';
    scale: number;
    createdAt: Date;
    updatedAt: Date;
}

export interface AssistantProps {
    assistant_id: string;
    thread_id?: string;
    root: HTMLElement;
}

const Assistant = ({ assistant_id, thread_id, root }: AssistantProps) => {
    const [isChatOpen, setChatOpen] = useState(false);
    const [assistantInfo, setAssistantInfo] = useState<AssistantInterface | null>(null);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleChatOpen = () => setChatOpen(true);
    const handleChatClose = () => setChatOpen(false);

    useEffect(() => {
        const fetchAssistantInfo = async () => {
            setLoading(true);
            await axios.get(`${SERVER_URL}/assistants/${assistant_id}`).then(response => {
                setAssistantInfo(response.data);
            }).catch(error => {
                setError(error.message);
            }).finally(() => setLoading(false));
        };
        
        if (assistant_id)
            fetchAssistantInfo();
    }, [assistant_id]);

    useEffect(() => {
        if (assistantInfo?.isOpen !== undefined) {
            setChatOpen(true);
        }
    }, [assistantInfo]);

    const LoadingComponent = () => (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            className={'bg-gray-100'}
        >
            <CircularProgress />
        </Box>
    );

    const ErrorComponent = ({ error }: { error: string }) => (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            className={'bg-red-100'}
        >
            <Typography variant="h6" color="error">An error occurred:</Typography>
            <Typography>{error}</Typography>
        </Box>
    );

    return (
        <>
            {isLoading ? (
                <LoadingComponent />
            ) : error ? (
                <ErrorComponent error={error} />
            ) : assistantInfo && <ThemeProvider theme={{ [THEME_ID]: generateTheme(root, assistantInfo.theme, assistantInfo.scale) }}>
                <ScopedCssBaseline>
                    {assistantInfo && <Box>
                        {assistantInfo.isFullPage && <ChatModalContent assistant={assistantInfo} thread_id={thread_id}></ChatModalContent>}
                        {!assistantInfo.isFullPage && <ChatIconButton onClick={handleChatOpen} />}
                        {!assistantInfo.isFullPage && <ChatModal assistant={assistantInfo} open={isChatOpen} onClose={handleChatClose} />}
                    </Box>}
                </ScopedCssBaseline>
            </ThemeProvider>}
        </>
    );
};

export default Assistant;